import { repoAtom } from "@/context/atom";
import { Typography, Dropdown, Modal, Tabs } from "antd";
import { useAtomValue } from "jotai";
import { ReactNode, useEffect, useState } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { Category } from "@/data/category";
import Editor from "./editor";
import CategoryTable from "./index";
import { useTranslation } from "react-i18next";

export default () => {
    const repo = useAtomValue(repoAtom);
    const categoryType = 2;
    const [tabItems, setTabItems] = useState<{ key: string, label: ReactNode }[]>();
    const [editCategory, setEditCategory] = useState<Category>();
    const [showEdit, setShowEdit] = useState(false);
    const [selectId, setSelectId] = useState<string>();
    const {t} = useTranslation();
    const menuItems = [{ key: 'edit', label: t('edit') }, { key: 'remove', label: t('remove') }];
    const toTabItem = (cv: Category) => ({
        key: cv.id,
        label: cv.name ?? '',
        closeIcon: <Dropdown menu={{
            items: menuItems,
            onClick: info => {
                switch(info.key) {
                    case 'edit':
                        setEditCategory(cv);
                        break;
                    case 'remove':
                        Modal.confirm({
                            title: <>{t('confirmRemoveCategory')} <Typography.Text type='danger' style={{fontSize: 16}}>{cv.name}</Typography.Text> ?</>,
                            onOk: () => repo.removeStoreCategory(cv.id).then(() => {
                                setTabItems(its => its?.filter(v => v.key != cv.id));
                                setSelectId(sid => sid == cv.id ? undefined : sid);
                            })
                        })
                        break;
                }
            }
        }} trigger={['click']} destroyPopupOnHide>
            <MoreOutlined style={{padding: 4}}/>
      </Dropdown>
    });
    useEffect(() => setShowEdit(editCategory != undefined), [editCategory]);
    useEffect(() => {
        repo.listStoreCategory({ root: true, type: categoryType }).then(v => v.items.map(toTabItem)).then(items => {
            items.reverse();
            setTabItems(items);
            setSelectId(items.length ? items[0].key : undefined);
        });
    }, []);
    const sid = selectId ?? (tabItems?.length ? tabItems[0].key : undefined);
    return <>
        <Tabs
            type="editable-card"
            style={{marginTop: 10}}
            accessKey={sid}
            onEdit={(_, action) => {
                if (action == 'add') {
                    setEditCategory({ id: '', type: categoryType });
                }
            }}
            onChange={setSelectId}
            items={tabItems}/>
        {sid ? <CategoryTable type={categoryType} categoryId={sid} /> : null}
        {editCategory ? <Modal
            open={showEdit}
            title={t(editCategory.id ? 'createCategory' : 'editCategory')}
            onCancel={() => setShowEdit(false)}
            afterClose={() => setEditCategory(undefined)}
            okButtonProps={{ htmlType: 'submit', form: 'edit-content' }}
            destroyOnClose>
            <Editor category={editCategory}
                onUpdate={v => {
                    if (tabItems) {
                        const ni = toTabItem(v);
                        const index = tabItems.findIndex(e => e.key == v.id);
                        if (index >= 0) {
                            tabItems[index] = ni;
                            setTabItems([...tabItems]);
                        } else {
                            setTabItems([...tabItems, ni]);
                        }
                    }
                    setShowEdit(false);
                }} />
        </Modal> : null}
    </>
}
