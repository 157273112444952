import { Form, Input } from "antd"
import { useAtomValue } from "jotai";
import { repoAtom } from "@/context/atom";
import { Category } from "@/data/category";
import { useTranslation } from "react-i18next";

export default (props: {
    category: Category,
    onUpdate: (category: Category) => void,
}) => {
    const { category, onUpdate } = props;
    const repo = useAtomValue(repoAtom);
    const {t} = useTranslation();
    return <Form id="edit-content"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={category}
        onFinish={async ov => {
            const pa = {...ov, categoryId: category.category?.id, type: category.type};
            const uv = await (category.id ? repo.modifyStoreCategory(category.id, pa)
                : repo.createStoreCategory(pa));
            onUpdate(uv);
        }}>
        <Form.Item label={t('categoryName')} name='name' rules={[{ required: true, message: 'Please input name!' }]}>
            <Input autoFocus placeholder={t('categoryName')} />
        </Form.Item>
    </Form>
}
