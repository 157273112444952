import { repoAtom } from "@/context/atom";
import { PageList, RequestPageInfo } from "@/data/common";
import { Button, Flex, Input, Space, Table, Typography, Modal } from "antd";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { ColumnType } from "antd/es/table";
import { CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Category } from "@/data/category";
import Editor from "./editor";
import styles from './index.module.css';
import { useTranslation } from "react-i18next";

export default (props: { type?: number, categoryId?: string }) => {
    const { categoryId, type } = props;
    const repo = useAtomValue(repoAtom);
    const [tableData, setTableData] = useState<PageList<Category>>();
    const [params, setParams] = useState({} as { search?: string });
    const [page, setPage] = useState<RequestPageInfo>({ size: 20 });
    const [editCategory, setEditCategory] = useState<Category>();
    const [showEdit, setShowEdit] = useState(false);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => setShowEdit(editCategory != undefined), [editCategory]);
    useEffect(() => {
        setLoading(true);
        const ops = { ...params, type: type, fillCategory: categoryId == undefined };
        (categoryId ? repo.listStoreCategoryChild(categoryId, ops, page) : repo.listStoreCategory({ ...ops, fillCategory: true }, page))
            .then(setTableData)
            .finally(() => setLoading(false));
    }, [categoryId, type, params]);
    
    const {t} = useTranslation();

    const columns: ColumnType<Category>[] = [{
        title: t('category'),
        key: 'name',
        render: (_, r, __) => {
            const s = params.search;
            if (!s?.length || !r.name) return r.name;
            const l = r.name.split(s);
            return <Typography.Text>{
                l.map((v, index) => index < l.length - 1 ? <>
                    {v}
                    <Typography.Text type='success'>{s}</Typography.Text>
                </> : v)
            }</Typography.Text>;
        }
    }, {
        title: t('createTime'),
        key: 'createTime',
        align: 'right',
        render: (_, r, __) => <Typography.Text
            type='secondary' >{dayjs(r.createTime).format('YYYY/MM/DD HH:mm')}</Typography.Text>
    }, {
        title: t('action'),
        key: 'actions',
        align: 'right',
        render: (_, r, __) => <Flex justify='flex-end' align="center">
            <Typography.Link onClick={() => setEditCategory(r)}>{t('edit')}</Typography.Link>
        </Flex>
    }
    ];

    return <>
        {selectedIds.length ? <Flex className={styles.optHeader} justify='space-between' align='center'>
            <Space>
                <Button icon={<DeleteOutlined />} danger onClick={() => {
                    Modal.confirm({
                        title: t('confirmRemoveCategory'),
                        content: `${t('confirmRemoveCategory')} ?`,
                        onOk: async () => {
                            await repo.removeStoreCategory(selectedIds);
                            if (tableData) {
                                const items = tableData.items.filter(v => selectedIds.indexOf(v.id) < 0);
                                const count = (tableData.count ?? 0) - tableData.items.length + items.length;
                                setTableData({ ...tableData, items: items, count: count });
                            }
                            setSelectedIds([]);
                        }
                    })
                }}>{t('remove')}</Button>
            </Space>
            <Button type='text' shape='circle' icon={<CloseOutlined />} danger onClick={() => setSelectedIds([])} />
        </Flex> :
            <Flex className={styles.header} justify='space-between'>
                <Space>
                    <Input.Search placeholder={t('inputNameToSearch')}
                        allowClear
                        onSearch={s => setParams((p: any) => ({ ...p, search: s }))} />
                </Space>
                <Space>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => setEditCategory({ id: '', type: type, category: categoryId ? {id: categoryId} : undefined })}>新增</Button>
                </Space>
            </Flex>
        }
        <Table<Category>
            loading={loading}
            rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedIds,
                onChange: v => setSelectedIds(v as string[])
            }}
            pagination={tableData ? {
                size: 'small',
                showTotal: (c) => <Typography.Text type='secondary'>{t('total')} {c}</Typography.Text>,
                onChange: (c, s) => setPage(p => ({ ...p, offset: (c - 1) * s })),
                current: tableData.size ? ((tableData.offset ?? 0) / tableData.size) + 1 : 1,
                pageSize: tableData.size,
                total: tableData.count
            } : undefined}
            rowKey='id'
            dataSource={tableData?.items}
            columns={columns} />
        {editCategory ? <Modal
            open={showEdit}
            title={`${editCategory.id ? t('edit') : t('create')}${t(categoryId ? 'childCategory' : 'category')}`}
            onCancel={() => setShowEdit(false)}
            afterClose={() => setEditCategory(undefined)}
            okButtonProps={{ htmlType: 'submit', form: 'edit-content' }}
            destroyOnClose>
            <Editor category={editCategory}
                onUpdate={v => {
                    if (tableData) {
                        let items = tableData.items;
                        const index = items.findIndex(e => e.id == v.id);
                        if (index >= 0) {
                            items[index] = v;
                            items = [...items];
                        } else {
                            items = [v, ...items];
                            tableData.count = (tableData.count ?? 0) + 1;
                        }
                        if (items != tableData.items) setTableData({ ...tableData, items: items });
                    }
                    setShowEdit(false);
                }} />
        </Modal> : null}
    </>;
}
