import { repoAtom } from "@/context/atom";
import { Attribute } from "@/data/attribute";
import { Good } from "@/data/good";
import Repository from "@/repository";
import { CloseOutlined } from "@ant-design/icons";
import { Typography, Table, Flex, InputNumber, Select, Button, Space, Tag } from "antd";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default (props: { good: Good, onUpdateAttribute: (c?: number) => void }) => {
    const { good, onUpdateAttribute } = props;
    const repo = useAtomValue(repoAtom);
    const [data, setData] = useState<Attribute[]>();
    useEffect(() => {
        repo.listStoreGoodAttribute(good.id, undefined, { size: 200 }).then(v => setData(v.items));
    }, [good.id]);
    const {t} = useTranslation();
    return <><Table<Attribute>
        style={{minHeight: 300}}
        loading={!data} dataSource={data}
        footer={() => <Space style={{ margin: 10 }}>
            <Typography.Text>{t('createIngredients')}</Typography.Text>
            <AddLine repo={repo} ids={data?.map(v => v.id)} onAdd={v => setData(data ? [...data, v] : [v])} />
        </Space>}
        rowKey='id'
        size='small'
        pagination={{
            hideOnSinglePage: true,
            pageSize: data?.length
        }}
        columns={[{
            title: t('ingredients'),
            key: 'name',
            render: (_, r, idx) => {
                return <Flex align='center'>
                    <Button icon={<CloseOutlined />}
                        style={{ flexShrink: 0 }}
                        danger type='text' onClick={data ? () => {
                            data.splice(idx, 1);
                            setData([...data]);
                        } : undefined}></Button>
                    <Flex vertical>
                        <Typography.Text style={{ fontSize: 12 }}>{r.name}</Typography.Text>
                        <Typography.Text type='secondary' style={{ fontSize: 10 }} /*editable={data ? {
                            onChange: v => text = v,
                            onEnd: () => {
                                const cv = data[idx].value;
                                if (cv) {
                                    cv.value = text;
                                    setData([...data]);
                                }
                            }
                        } : undefined}*/>
                            {r.value?.value?.length ? r.value.value : r.description}
                        </Typography.Text>
                    </Flex>
                </Flex>
            }
        }, {
            title: '打分',
            key: 'score',
            render: (_, r, idx) => {
                const score = r.value?.score ?? r.score;
                const maxScore = r.value?.maxScore ?? r.maxScore ?? score;
                return <Tag color={!maxScore ? 'gray' : maxScore < 3 ? 'green' : maxScore < 6 ? 'orange' : 'red'}>{score}{maxScore != score ? ` - ${maxScore}` : ''}</Tag>;
        }/*<Input
            disabled
                defaultValue={r.value?.score
                    ? `${r.value.score}${r.value.maxScore && r.value.maxScore > r.value.score ? `-${r.value.maxScore}` : ''}` : undefined}
                variant='filled'
                onChange={data ? v => {
                    const tx = v.currentTarget.value.split('-');
                    const cv = data[idx].value;
                    if (cv) {
                        const score = parseInt(tx[0].trim());
                        data[idx].value = {
                            ...cv, score: score,
                            maxScore: tx.length > 1 ? parseInt(tx[1].trim()) : score
                        };
                        setData([...data]);
                    }
                } : undefined}
                placeholder='打分'
                style={{ width: 60 }} size='small' /> */
        }, {
            title: t('activeIngredients'),
            key: 'ingredient',
            render: (_, r, idx) => <Select size="small" placeholder="无" allowClear
            disabled
                defaultValue={r.value?.ingredient ?? r.ingredient}
                variant='filled'
                onChange={data ? v => {
                    const cv = data[idx].value;
                    if (cv) {
                        data[idx].value = { ...cv, ingredient: v };
                        setData([...data]);
                    }
                } : undefined}
                options={[
                    { label: 'UVA', value: 1 },
                    { label: 'UVB', value: 2 },
                    { label: t('beneficialIngredients'), value: 3 }]} />
        }, {
            title: t('riskOfAcne'),
            key: 'risk',
            render: (_, r, idx) => <Select size="small" placeholder={t('none')} allowClear
            disabled
                defaultValue={r.value?.risk ?? r.risk}
                onChange={data ? v => {
                    const cv = data[idx].value;
                    if (cv) {
                        data[idx].value = { ...cv, risk: v };
                        setData([...data]);
                    }
                } : undefined}
                variant='filled'
                options={[{
                    label: t('low'),
                    value: 1
                }, { label: t('medium'), value: 2 }, { label: t('high'), value: 3 }]} />
        }, {
            title: t('concentrationRatio'),
            align: 'end',
            key: 'rate',
                render: (_, r, idx) => <InputNumber
                    disabled
                    defaultValue={r.value?.rate}
                    variant='filled'
                    onChange={data ? v => {
                        const cv = data[idx].value;
                        if (cv) {
                            data[idx].value = { ...cv, rate: v ?? undefined };
                            setData([...data]);
                        }
                    } : undefined}
                    min={0}
                    max={1}
                    step={0.1}
                    placeholder={t('concentration')} style={{ width: 60 }} size='small' />
            }]}
    />
        <Space style={{ position: 'absolute', top: 10, right: 10 }}>
            <Button type='primary' onClick={data ? async () => {
                await repo.setStoreGoodAttribute(good.id, data.map(v => ({
                    id: v.id,
                    value: v.value ?? { value: '' }
                })));
                onUpdateAttribute(data.length);
            } : undefined}>{t('confirm')}</Button>
        </Space>
    </>;
}

const AddLine = (props: {
    repo: Repository,
    ids?: string[],
    onAdd: (attr: Attribute) => void
}) => {
    const { repo, ids, onAdd } = props;
    const [data, setData] = useState<Attribute[]>([]);
    const {t} = useTranslation();
    return <Select
        placeholder={t('selectIngredients')}
        showSearch
        size='small'
        style={{ minWidth: 200 }}
        defaultActiveFirstOption={false}
        notFoundContent={null}
        filterOption={false}
        allowClear
        value={null}
        onSelect={v => {
            const d = data.find(e => e.id == v);
            if (d) onAdd(d);
        }}
        options={data.map(v => ({
            value: v.id,
            label: v.name,
            disabled: ids && ids.indexOf(v.id) >= 0
        }))}
        onSearch={s => {
            repo.listStoreAttribute(s.length ? { search: s } : undefined)
                .then(ds => setData(ds.items));
        }}
    />
}