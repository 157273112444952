import styles from './index.module.css';
import {
  Row,
  Button,
  Input,
  Space,
  Col,
  Checkbox,
  Spin,
  Typography,
  Tabs,
  notification,
  Form } from "antd";
import {
  CodeOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  PhoneOutlined,
  UserOutlined
} from '@ant-design/icons';
import {useEffect, useState} from "react";
import {VerifyCode} from "@/data/common";
import {useTranslation} from "react-i18next";
import {useAtomValue} from "jotai";
import {repoAtom} from "@/context/atom";
import Repository from "@/repository";
import moment from "dayjs";
import {UserSession} from "@/data/user-session";

export default (props: {onLogin: (session: UserSession, remember: boolean) => void}) => {
  const [rememberLogin, setRememberLogin] = useState(true);
  const repo = useAtomValue(repoAtom);
  const {onLogin} = props;
  const {t} = useTranslation();
  const [logining, setLogining] = useState(false);
  return <Space className={styles.background} direction="vertical" size={0}>
    <Tabs
        items={[{
          key: 'password',
          label: t('passwordLogin'),
          disabled: logining,
          children: <PasswordLogin repo={repo} commiting={logining} onCommiting={setLogining}
                                   onLogin={s => onLogin(s, rememberLogin)}/>
        }, {
          key: 'phone',
          label: t('phoneLogin'),
          disabled: logining,
          children: <PhoneLogin repo={repo} commiting={logining} onCommiting={setLogining}
                                onLogin={s => onLogin(s, rememberLogin)}/>
        }]}/>
    <Row justify='end'>
      <Checkbox
          style={{marginTop: 10}}
          checked={rememberLogin}
          onChange={v => setRememberLogin(v.target.checked)}>{t('rememberLogin')}</Checkbox>
    </Row>
  </Space>;
};

const PasswordLogin = (props: {
  repo: Repository,
  commiting?: boolean
  onCommiting: (start: boolean) => void,
  onLogin: (session: UserSession) => void,
}) => {
  const {repo, commiting, onCommiting, onLogin} = props;
  const iconStyle = {opacity: 0.4};
  const {t} = useTranslation();
  const [verifyCode, setVerifyCode] = useState<VerifyCode & {error?: any}>({});
  const [requestVerifyCodeCount, setRequestVerifyCodeCount] = useState(0);

  useEffect(() => {
    setVerifyCode({});
    repo.getImageVerifyCode(verifyCode.key).then(setVerifyCode).catch(e => setVerifyCode({error: e}));
  }, [repo, requestVerifyCodeCount]);

  return <Form onFinish={v => {
    onCommiting(true);
    repo.login(v.name, v.password, {
      key: verifyCode.key ?? '',
      code: v.code
    })
        .then(onLogin)
        .catch(e => notification.error({
          message: JSON.stringify(e)
        }))
        .finally(() => onCommiting(false));
    console.log(v);
  }}>
    <Form.Item name='name' rules={[{ required: true, message: t('needInputLoginName') }]}>
      <Input
          disabled={commiting}
          prefix={<UserOutlined style={iconStyle}/>}
          placeholder={t('account') ?? undefined} size="large" autoFocus/>
    </Form.Item>
    <Form.Item name='password' rules={[{ required: true, message: t('needInputLoginPassword') }]}>
      <Input.Password placeholder={t('inputPassword') ?? undefined} size="large"
                      disabled={commiting}
                      prefix={<LockOutlined style={iconStyle}/>}
                      iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
      />
    </Form.Item>
    <Form.Item name='code' rules={[{ required: true, message: t('needInputLoginVerifyCode') }]}>
      <Row align="middle" gutter={20}>
        <Col span={14}>
          <Input
              disabled={commiting}
              placeholder={t('inputVerifyCode') ?? undefined} size="large" prefix={<CodeOutlined style={iconStyle}/>}/>
        </Col>
        <Col span={10}>
          <div onClick={commiting ? undefined : () => {
            setVerifyCode({});
            setRequestVerifyCodeCount(v => v + 1);
          }} className={styles.verifyCode}
               style={{backgroundImage: `url(${verifyCode.image})`}}>
            {verifyCode.error ? <Typography.Text>{t('fetchVerifyCodeFail')}</Typography.Text>
                : !verifyCode.image ? <Spin size='small'/> : null}
          </div>
        </Col>
      </Row>
    </Form.Item>
    <Button type="primary"
            size="large"
            htmlType='submit'
            loading={commiting}
            block
            className={styles.button}>{t('login')}</Button>
  </Form>
};

const PhoneLogin = (props: {
  repo: Repository,
  commiting?: boolean
  onCommiting: (start: boolean) => void
  onLogin: (session: UserSession) => void,
}) => {
  const { repo, commiting, onCommiting, onLogin} = props;
  const iconStyle = {opacity: 0.4};
  const {t} = useTranslation();
  const [verifyCode, setVerifyCode] = useState<VerifyCode|null>();
  const [countdown, setCountdown] = useState(0);
  useEffect(() => {
    if (verifyCode) {
      const { createTime, refreshDuration } = verifyCode;
      if (createTime && refreshDuration) {
        const base = moment(createTime).add(refreshDuration, 'ms');
        const seconds = base.diff(moment(), 's');
        console.log(createTime)
        console.log(refreshDuration)
        setCountdown(seconds);
        let timer: any = null;
        timer = seconds > 0 ? setInterval(() => {
          const seconds = base.diff(moment(), 's');
          setCountdown(seconds);
          if (seconds <= 0) clearInterval(timer);
        }, 200) : timer;
        if (timer) return () => clearInterval(timer);
      }
    }
  }, [verifyCode]);
  const [form] = Form.useForm();
  const phone = Form.useWatch('phone', form);
  return <Form form={form} onFinish={v => {
    const phone = v.phone;
    const code = v.code;
    onCommiting(true);
    repo.verifyByPhone(phone, code)
        .then(onLogin)
        .catch(e => notification.error({
          message: JSON.stringify(e)
        }))
        .finally(() => onCommiting(false));
  }}>
    <Form.Item name='phone' rules={[{ required: true, message: t('needInputLoginPhone') }]}>
      <Input
          disabled={commiting}
          prefix={<PhoneOutlined style={iconStyle}/>}
          placeholder={t('phone')}
          inputMode='tel'
          size="large"
          autoFocus/>
    </Form.Item>
    <Form.Item name='code' rules={[{ required: true, message: t('needInputLoginPhoneVerifyCode') }]}>
      <Space.Compact>
        <Input
            prefix={<CodeOutlined style={iconStyle}/>}
            disabled={commiting}
            placeholder={t('verifyCode')}
            size="large"/>
        <Button size='large'
                style={{minWidth: 120, opacity: 0.8}}
                loading={verifyCode === null}
                disabled={countdown > 0 || commiting || !phone}
                onClick={phone ? () => {
                  setVerifyCode(phone);
                  repo.getPhoneVerifyCode(phone)
                      .then(v => {
                        setVerifyCode(v);
                        // if (v.code) form.setFieldValue("code", v.code)
                      })
                      .catch(() => setVerifyCode(undefined));
                } : undefined}>{countdown ? `${countdown}s` : t('requestVerifyCode')}</Button>
      </Space.Compact>
    </Form.Item>
    <Form.Item>
      <Input size='large' style={{ visibility: 'hidden' }}/>
    </Form.Item>
    <Button type="primary"
            size="large"
            htmlType='submit'
            loading={commiting}
            block
            className={styles.button}>{t('login')}</Button>
  </Form>
}
