import { Form, Input } from "antd"
import { useAtomValue } from "jotai";
import { repoAtom } from "@/context/atom";
import { User } from "@/data/user";
import { useTranslation } from "react-i18next";

export default (props: {
    user: User,
    onUpdate: (user?: User) => void,
}) => {
    const { user, onUpdate } = props;
    const repo = useAtomValue(repoAtom);
    const {t} = useTranslation();
    return <Form id="edit-content"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={user}
        onFinish={async v => {
            if (user.id) {
                await repo.changeUserPassword(user.id, v.password);
                onUpdate();
            } else {
                const u = await repo.createUser({
                    name: v.name,
                    password: v.password
                });
                onUpdate(u);
            }
        }}>
        <Form.Item label={t('userName')} name='name'
         rules={user.id ? undefined : [{ required: true, message: t('inputUserName') }]}>
            <Input autoFocus placeholder={t('inputUserName')} disabled={user.id.length > 0}/>
        </Form.Item>
        <Form.Item label={t('password')} name='password' rules={[{ required: true, message: t('inputPassword') }]}>
            <Input.Password placeholder={t("inputPassword")}/>
        </Form.Item>
    </Form>
}
