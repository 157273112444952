import { Attribute } from "@/data/attribute"
import { Form, Input, InputNumber, Select, Space } from "antd"
import { useAtomValue } from "jotai";
import { repoAtom } from "@/context/atom";
import { Category } from "@/data/category";
import { CategoryOption } from "@/component/category/opton";
import { useTranslation } from "react-i18next";

export default (props: {
    attribute: Attribute,
    options?: CategoryOption[],
    onUpdate: (attr: Attribute) => void,
}) => {
    const { attribute, options, onUpdate } = props;
    const repo = useAtomValue(repoAtom);
    const {t} = useTranslation();
    return <Form id="edit-content"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={{
            ...attribute,
            scores: attribute.score && attribute.maxScore ? [attribute.score, attribute.maxScore] : attribute.score ? [attribute.score, attribute.score] : undefined,
            categoryId: attribute.category?.id
        }}
        onFinish={async ov => {
            const scores = ov.scores ? ov.scores as [number | null, number | null] : undefined;
            const nv = scores ? { ...ov, score: scores[0] ?? undefined, maxScore: scores[1] ?? undefined } : ov;
            const uv = await (attribute.id ? repo.modifyStoreAttribute(attribute.id, !nv.categoryId && attribute.category ? { ...nv, categoryId: '' } : nv)
                : repo.createStoreAttribute(nv));
            let c: Category | undefined = undefined;
            if (options && nv.categoryId) {
                for (let o of options) {
                    if (o.value == nv.categoryId) c = o.category; else if (o.options) for (let io of o.options) {
                        if (io.value == nv.categoryId) {
                            c = io.category;
                            break;
                        }
                    }
                    if (c) break;
                }
            }
            uv.category = c;
            onUpdate(uv);
        }}>
        <Form.Item label={t('ingredientsName')} name='name' rules={[{ required: true, message: 'Please input name!' }]}>
            <Input autoFocus placeholder={t('ingredientsName')}/>
        </Form.Item>
        <Form.Item label={t('useage')} name='description'>
            <Input placeholder={t("ingredientsUseage")}/>
        </Form.Item>
        <Form.Item label={t('safetyPoints')} name='scores'>
            <ScoreField />
        </Form.Item>
        <Form.Item label={t('activeIngredients')} name='ingredient'>
            <Select placeholder={t("activeIngredients")} allowClear options={[
                { label: 'UVA', value: 1 }, 
                { label: 'UVB', value: 2 },
                { label: t('beneficialIngredients'), value: 3 } ]}/>
        </Form.Item>
        <Form.Item label={t('riskOfAcne')} name='risk'>
            <Select placeholder={t("riskOfAcne")} allowClear options={[
                { label: t('low'), value: 1 }, 
                { label: t('medium'), value: 2 },
                { label: t('high'), value: 3 } ]}/>
        </Form.Item>
        {options ? <Form.Item label={t('category')} name='categoryId'>
            <Select options={options} placeholder={t('noCategory')} allowClear></Select>
        </Form.Item> : null}
    </Form>
}

const ScoreField = (props: { value?: [number | null, number | null], onChange?: (value?: [number | null, number | null]) => void }) => {
    const { value, onChange } = props;
    return <Space>
        <InputNumber min={0} step={1} value={value ? value[0] : undefined} onChange={onChange ? v => onChange([v, value ? value[1] : null]) : undefined} />
        -
        <InputNumber min={0} step={1} value={value ? value[1] : undefined} onChange={onChange ? v => onChange([value ? value[0] : null, v]) : undefined} />
    </Space>;
}