import { CSSProperties, useEffect, useState } from "react";
import { Dropdown, Button } from 'antd';
import { setCurrentLang } from "@/i18n/config";
import { useTranslation } from "react-i18next";

const langItems = [{
  label: 'English',
  key: 'en',
}, {
  label: '简体中文',
  key: 'zh-CN',
}, {
  label: 'Indonesia',
  key: 'id',
}];

export default (props: { style?: CSSProperties, className?: string }) => {
  const { style, className } = props;
  const [lang, setLang] = useState<{
    label: string,
    key: string,
  }>();
  const { i18n } = useTranslation();
  useEffect(() => {
    const cl = i18n.language;
    setLang(langItems.find(v => v.key === cl) ?? langItems[0]);
  }, [i18n]);
  return <div className={className} style={style}>
    <Dropdown menu={{
      selectable: true,
      selectedKeys: lang ? [lang.key] : [],
      items: langItems,
      onSelect: e => {
        const lang = langItems.find(v => v.key === e.key) ?? langItems[0];
        setLang(lang);
        const cl = i18n.language;
        const ncl = lang?.key ?? '';
        if (cl !== ncl) i18n.changeLanguage(ncl).then(() => setCurrentLang(ncl));
      }
    }} trigger={['click']}>
      <Button
        style={{ color: 'white' }}
        size='small'
        type='text'
        loading={!lang}>{lang?.label}</Button>
    </Dropdown>
  </div>
};
