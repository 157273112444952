import { repoAtom } from "@/context/atom";
import { PageList, RequestPageInfo } from "@/data/common";
import { Attribute } from "@/data/attribute";
import { Button, Flex, Input, Select, Space, Table, Typography, Dropdown, Modal } from "antd";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { ColumnType } from "antd/es/table";
import { CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Category } from "@/data/category";
import Editor from "./editor";
import styles from './index.module.css';
import { CategoryOption } from "@/component/category/opton";
import { useTranslation } from "react-i18next";

export default () => {
    const repo = useAtomValue(repoAtom);
    const [tableData, setTableData] = useState<PageList<Attribute>>();
    const [params, setParams] = useState({ fillCategory: true } as { search?: string, categoryId?: string  });
    const [page, setPage] = useState<RequestPageInfo>({ size: 20 });
    const [categories, setCategories] = useState<CategoryOption[]>();
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [editAttribute, setEditAttribute] = useState<Attribute>();
    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    useEffect(() => setShowEdit(editAttribute != undefined), [editAttribute]);
    useEffect(() => {
        repo.listStoreCategory({fillCategory: true, type: 2}).then(v => {
            const items = v.items;
            const cs = [];
            if (items) {
                const map = new Map();
                for (let e of items) {
                    const pid = e.category?.id;
                    if (pid) { 
                        const t = map.get(pid) ?? [];
                        t.push({
                            value: e.id,
                            category: e,
                            label:<Typography.Text>{e.name ?? ''}</Typography.Text>
                        });
                        map.set(pid, t);
                    } else cs.push({
                        value: e.id,
                        category: e,
                        label: <span>{e.name}</span>,
                        options: []
                    });
                }
                cs.forEach(e => e.options = map.get(e.value));
            }
            setCategories(cs);
            return null;
        });
    }, []);
    useEffect(() => {
        setLoading(true);
        repo.listStoreAttribute(params, page).then(setTableData).finally(() => setLoading(false));
    }, [params, page]);
    const columns: ColumnType<Attribute>[] = [{
        title: t('ingredients'),
        key: 'name',
        render: (_, r, __) => { 
            const s = params.search;
            if (!s?.length || !r.name) return r.name; 
            const l = r.name.split(s);
            return <Typography.Text>{
                l.map((v, index) => index < l.length - 1 ? <>
                    {v}
                    <Typography.Text type='success'>{s}</Typography.Text>
                </> : v)
                }</Typography.Text>; 
        }
    }, {
        title: t('belong'),
        key: 'category',
        render: (_, r, __) => <Typography.Text
        type='secondary'>{r.category?.name}</Typography.Text>,
    }, {
        title: t('useage'),
        key: 'description',
        dataIndex: 'description',
        }, {
            title: t('safetyPoints'),
            key: 'score',
            render: (_, r, __) => { 
                const maxScore = r.maxScore ?? r.score;
                if (maxScore == undefined) return <Typography.Text type='secondary'>{t('none')}</Typography.Text>;
                return <Typography.Text type={maxScore < 3 ? 'success' : maxScore < 6 ? 'warning' : 'danger'}>
                    {r.score}{maxScore != r.score ? ` - ${maxScore}` : null}
                </Typography.Text>;
            }
        }, {
            title: t('activeIngredients'),
            key: 'ingredient',
            render: (_, r, __) => r.ingredient ? <Typography.Text type='success'>{r.ingredient == 1 ? 'UVA' : r.ingredient == 2 ? 'UVB' : t('beneficialIngredients')}</Typography.Text> : <Typography.Text type='secondary'>-</Typography.Text>
        }, {
            title: t('riskOfAcne'),
            key: 'risk',
            render: (_, r, __) => <Typography.Text type={r.risk == 1 ? 'success' : r.risk == 2 ? 'warning' : r.risk ? 'danger' : 'secondary'}>{r.risk == 1 ? t('low') : r.risk == 2 ? t('medium') : r.risk == 3 ? t('high') : (r.risk ?? '-')}</Typography.Text>
        }, {
            title: t('createTime'),
            key: 'createTime',
            align: 'right',
            render: (_, r, __) => <Typography.Text
                type='secondary' >{dayjs(r.createTime).format('YYYY/MM/DD HH:mm')}</Typography.Text>
        }, {
            title: t('action'),
            key: 'actions',
            align: 'right',
            render: (_, r, __) => <Flex justify='flex-end' align="center">
                <Typography.Link onClick={() => setEditAttribute(r)}>{t('edit')}</Typography.Link>
            </Flex>
        }
    ];
    return <>
        {selectedIds.length ? <Flex className={styles.optHeader} justify='space-between' align='center'>
            <Space>
                {categories ? <Dropdown
                    trigger={['click']}
                    menu={{
                        onClick: async ({key}) => {
                            const items = await (key.length ? repo.setStoreAttributeCategory(key, selectedIds).then(() => {
                                let c: Category | undefined = undefined;
                                for (let v of categories) {
                                    if (v.value == key) c = v.category; else if (v.options) {
                                        for (let v1 of v.options) {
                                            if (v1.value == key) {
                                                c = v1.category;
                                                break;
                                            }
                                        }
                                    }
                                    if (c) break;
    
                                }
                                return params.categoryId && params.categoryId != key ? tableData?.items.filter(gv => selectedIds.indexOf(gv.id) < 0)
                                    : tableData?.items.map(gv => ({
                                        ...gv,
                                        category: c 
                                    }));
                            }) : repo.removeStoreAttributeCategory(selectedIds).then(() => params.categoryId ? tableData?.items.filter(gv => selectedIds.indexOf(gv.id) < 0) : tableData?.items.map(gv => ({
                                ...gv,
                                category: undefined
                            }))));
                            if (items) { 
                                var count = tableData?.count ?? 0;
                                if (tableData?.items && items.length != tableData.items.length) count += items.length - tableData.items.length;
                                setTableData({...tableData, items: items, count: count});
                            }
                            setSelectedIds([]);
                        },
                        items: [{
                            key: '',
                            label: t('removeCategory'),
                            danger: true
                        }, ...categories.map(v => ({
                            ...v,
                            key: v.value,
                            type: 'group' as 'group',
                            children: v.options?.map(e => ({ 
                                ...e,
                                key: e.value 
                            }))
                        }))]
                    }}>
                    <Button type='primary' icon={<PlusOutlined />}>分类</Button>
                </Dropdown> : null}
                <Button icon={<DeleteOutlined />} danger onClick={() => {
                    Modal.confirm({
                        title: t('confirmRemoveIngredients'),
                        content: `${t('confirmRemoveIngredients')} ?`,
                        onOk: async () => {
                            await repo.removeStoreAttribute(selectedIds);
                            if (tableData) {
                                const items = tableData.items.filter(v => selectedIds.indexOf(v.id) < 0);
                                const count = (tableData.count ?? 0) - tableData.items.length + items.length;
                                setTableData({...tableData, items: items, count: count});
                            }
                            setSelectedIds([]);
                        }
                    })
                }}>{t('remove')}</Button>
            </Space>
            <Button type='text' shape='circle' icon={<CloseOutlined/>} danger onClick={() => setSelectedIds([])}/>
        </Flex> :
            <Flex className={styles.header} justify='space-between'>
                <Space>
                    <Input.Search placeholder={t('inputNameToSearch')}
                        allowClear
                        onSearch={s => setParams((p: any) => ({ ...p, search: s }))} />
                    <Select
                        style={{ minWidth: 100 }}
                        value={params.categoryId}
                        onChange={v => setParams(p => ({ ...p, categoryId: v }))}
                        allowClear
                        placeholder={t("belongCategory")}
                        options={categories} />
                </Space>
                <Space>
                    <Button type='primary' icon={<PlusOutlined />} onClick={() => setEditAttribute({id: ''})}>{t('createIngredients')}</Button>
                </Space>
            </Flex>}
        <Table<Attribute>
            loading={loading}
            rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedIds,
                onChange: v => setSelectedIds(v as string[])
            }}
            pagination={tableData ? {
                size: 'small',
                showTotal: (c) => <Typography.Text type='secondary'>{t('total')} {c}</Typography.Text>,
                onChange: (c, s) => setPage(p => ({...p, offset: (c - 1) * s })),
                current: tableData.size ? ((tableData.offset ?? 0) / tableData.size) + 1 : 1,
                pageSize: tableData.size,
                total: tableData.count
            } : undefined}
            rowKey='id'
            dataSource={tableData?.items}
            columns={columns} />
        {editAttribute ? <Modal
            open={showEdit}
            title={t(editAttribute.id ? 'editIngredients' : 'createIngredients')} 
            onCancel={() => setShowEdit(false)}
            afterClose={() => setEditAttribute(undefined)}
            okButtonProps={{htmlType: 'submit', form: 'edit-content' }}
            destroyOnClose>
            <Editor attribute={editAttribute} 
            options={categories}
            onUpdate={v => {
                if (tableData) {
                    let items = tableData.items;
                    const index = items.findIndex(e => e.id == v.id);
                    const isCurrent = !params.categoryId || v.category?.id == params.categoryId;
                    if (index >= 0) { 
                        isCurrent ? items[index] = v : items.splice(index, 1); 
                        items = [...items];
                    } else if (isCurrent) {
                        items = [v, ...items];
                        tableData.count = (tableData.count ?? 0) + 1;
                    }
                    if (items != tableData.items) setTableData({...tableData, items: items});
                }
                setShowEdit(false);
            }} />
        </Modal> : null}
    </>
}
