import axios, { AxiosRequestConfig } from "axios";
import {PageList, RequestPageInfo, VerifyCode} from "./data/common";
import {UserSession} from "@/data/user-session";
import {Resource, ResourceTask} from "@/data/resource";
import { Category } from "./data/category";
import { Good } from "./data/good";
import { Attribute, AttributeValue } from "./data/attribute";
import { User } from "./data/user";

const LOGIN_SESSION = 'login-session';

class Repository {

  private appUrl = process.env.REACT_APP_HOST_URL!;
  // private appUrl = 'http://10.0.0.254:9921';
  // private appUrl = 'http://localhost:9991';
  // private appUrl = 'https://dev.mdvsc.cc:8000';
  // private appUrl = 'http://10.0.0.17:9991';
  // private appUrl = 'https://dev.mdvsc.cc:8000';
  private appId = "a1e77dfa-75a8-4328-b1e6-fdb81ddb0da0";

  private session: {
    accessToken?: string;
    talentId?: string;
  } = {};

  constructor() {
    const host = document
        ?.querySelector('meta[name="host"]')
        ?.getAttribute("content")
    if (host) this.appUrl = host.indexOf(":/") > 0 ? host : `http://${host}`;
    this.restoreSession();
  }

  getResourceUrl = (resource: Resource) => resource.uri ? this.processUrl(resource.uri) : this.getResourceUrlById(resource.id);

  getResourceUrlById(resourceId: string) {
    return this.processUrl(`static-resources/${resourceId}`);
  }

  getStoreUploadResourcePath(options?: { library?: boolean}) {
    return this.processUrl(`store-session/actions/uploadResource${requestParams(options)}`);
  }

  getStoreUploadVideoResourcePath(options?: { library?: boolean}) {
    return this.processUrl(`store-session/actions/uploadVideoResource${requestParams(options)}`);
  }

  getStoreUploadAudioResourcePath(options?: { library?: boolean}) {
    return this.processUrl(`store-session/actions/uploadAudioResource${requestParams(options)}`);
  }

  getCommonHeaders() {
    const {accessToken, talentId} = this.session;
    const headers: {
      talentId?: string,
      Authorization?: string,
      appId: string,
    } = { appId: this.appId };
    if (talentId) headers.talentId = talentId;
    if (accessToken) headers.Authorization = accessToken;
    return headers;
  }

  hasSession() {
    return this.session.accessToken != undefined;
  }

  restoreSession() {
    this.session = JSON.parse(localStorage.getItem(LOGIN_SESSION) ?? '{}');
  }

  storeSession() {
    localStorage.setItem(LOGIN_SESSION, JSON.stringify(this.session));
  }

  removeSession() {
    localStorage.removeItem(LOGIN_SESSION);
  }

  setSession(session: {token?: string, talentId?: string}) {
    this.session.accessToken = session.token ?? this.session.accessToken;
    this.session.talentId = session.talentId ?? this.session.talentId;
  }

  processUrl(...paths: string[]): string {
    if (paths.length <= 0) return this.appUrl;
    const first = paths[0];
    return joinUrl(first.indexOf(':/') > 0 ? '' : this.appUrl, ...paths);
  }

  processStaticUrl(...paths: string[]): string {
    if (paths.length <= 0) return this.appUrl;
    const first = paths[0];
    return first.indexOf(':/') > 0 ? joinUrl('', ...paths) : joinUrl(this.appUrl, 'static', ...paths);
  }


  private async axiosRequest(params: AxiosRequestConfig) {
    params.url = this.processUrl(params.url ?? '');
    const headers: any = { ...params.headers, appId: this.appId };
    const s = this.session;
    if (s.talentId) headers.talentId = s.talentId;
    if (s.accessToken != null) headers.Authorization = s.accessToken;
    params.headers = headers;
    params.method = params.method ?? (params.data ? 'POST' : 'GET');
    return axios(params);
  }

  private async requestListApi<T = any>(params: AxiosRequestConfig): Promise<PageList<T>> {
    const response = (await this.axiosRequest(params));
    const {offset, size, count} = response.headers;
    return {
      offset: parseInt(offset ?? '0'),
      size: size ? parseInt(size) : undefined,
      count: count ? parseInt(count) : undefined,
      items: Array.isArray(response.data) ? response.data : []
    };
  }

  private async requestApi<T = any>(params: AxiosRequestConfig): Promise<T> {
    const d = (await this.axiosRequest(params)).data;
    return d as T
  }

  login = (login: string, password: string, verify: {
    key: string,
    code: string,
  }) => this.requestApi<UserSession>({
    url: 'user-session',
    method: 'PUT',
    data: {
      login: login,
      password: password,
    },
    headers: {verifyCode: `CODE:${verify.key}=${verify.code}`}
  });

  verifyByPhone = (phone: string, code: string) => this.requestApi<UserSession>({
    url: 'app-session/actions/verifyByPhone',
    method: 'POST',
    data: {
      phone: phone,
      code: code,
    },
  });

  info = () => this.requestApi<UserSession>({ url: 'user-session', method: 'GET' });

  getPhoneVerifyCode = (phone: string, params?: { code: string }) => this.requestApi<VerifyCode>({
    url: `app-session/actions/getPhoneVerifyCode`,
    data: { ...params, phone: phone }
  });

  getImageVerifyCode = (key?: string) => this.requestApi<VerifyCode>({
    url: `actions/getVerifyCode`,
    method: 'POST',
    data: key
  });

  listStoreResource = (
    params?: {
      search?: string,
      categoryId?: string | string[],
      type?: number|number[],
      status?: string,
      fillCategory?: boolean
    }, 
    page?: RequestPageInfo
  ) => this.requestListApi<Resource>({
    url: `store-session/resources${requestParams(params)}`,
    method: 'GET',
    headers: createPageHeader(page)
  });

  listStoreResourceTask = (params?: {
    search?: string,
    categoryId?: string|string[],
    status?: string,
    fillCategory?: boolean
  }, page?: RequestPageInfo) => this.requestListApi<ResourceTask>({
    url: `store-session/resourceTasks${requestParams(params)}`,
    method: 'GET',
    headers: createPageHeader(page)
  });

  listStoreCategory = (params?: {
    search?: string,
    type?: number | number[],
    fillCategory?: boolean,
    root?: boolean,
  }, page?: RequestPageInfo) => this.requestListApi<Category>({
    url: `store-session/categories${requestParams(params)}`,
    method: 'GET',
    headers: createPageHeader(page)
  });

  listStoreCategoryChild = (categoryId: string, params?: {
    search?: string,
    type?: number | number[],
  }, page?: RequestPageInfo) => this.requestListApi<Category>({
    url: `store-session/categories/${categoryId}/categories${requestParams(params)}`,
    method: 'GET',
    headers: createPageHeader(page)
  });

  listStoreGood = (params?: {
    search?: string,
    type?: number | number[],
    fillAttributeSize?: boolean
  }, page?: RequestPageInfo) => this.requestListApi<Good>({
    url: `store-session/goods${requestParams(params)}`,
    method: 'GET',
    headers: createPageHeader(page)
  });

  listStoreAttribute = (params?: {
    search?: string,
    type?: number | number[],
    fillCategory?: boolean,
  }, page?: RequestPageInfo) => this.requestListApi<Attribute>({
    url: `store-session/attributes${requestParams(params)}`,
    method: 'GET',
    headers: createPageHeader(page)
  });

  listStoreGoodAttribute = (goodId: string, params?: {
    fillCategory?: boolean,
  }, page?: RequestPageInfo) => this.requestListApi<Attribute>({
    url: `store-session/goods/${goodId}/attributes${requestParams(params)}`,
    method: 'GET',
    headers: createPageHeader(page)
  });

  listUser = (params?: {
    search?: string,
    fillPermission?: boolean
  }, page?: RequestPageInfo) => this.requestListApi<User>({
    url: `users${requestParams(params)}`,
    method: 'GET',
    headers: createPageHeader(page)
  });

  setStoreAttributeCategory = (categoryId: string, attributeId: string|string[]) => this.requestApi({
    url: `store-session/categories/${categoryId}/actions/applyToAttribute`,
    data: typeof attributeId == 'string' ? [attributeId] : attributeId
  });

  removeStoreAttributeCategory = (attributeId: string|string[]) => this.requestApi({
    url: `store-session/actions/removeAttributeCategory`,
    data: typeof attributeId == 'string' ? [attributeId] : attributeId
  });

  setStoreGoodCategory = (categoryId: string, goodId: string|string[]) => this.requestApi({
    url: `store-session/categories/${categoryId}/actions/applyToGood`,
    data: typeof goodId == 'string' ? [goodId] : goodId
  });

  removeStoreGoodCategory = (goodId: string|string[]) => this.requestApi({
    url: `store-session/actions/removeGoodCategory`,
    data: typeof goodId == 'string' ? [goodId] : goodId
  });

  removeStoreAttribute = (attributeId: string|string[]) => this.requestApi({
    url: `store-session/actions/removeAttribute`,
    data: typeof attributeId == 'string' ? [attributeId] : attributeId
  });

  removeStoreCategory = (categoryId: string|string[]) => this.requestApi({
    url: `store-session/actions/removeCategory`,
    data: typeof categoryId == 'string' ? [categoryId] : categoryId
  });

  removeStoreGood = (goodId: string|string[]) => this.requestApi({
    url: `store-session/actions/removeGood`,
    data: typeof goodId == 'string' ? [goodId] : goodId
  });

  createStoreCategory = (params: { name: string, type?: number }) => this.requestApi<Category>({
    url: `store-session/categories`,
    data: params
  });

  modifyStoreCategory = (categoryId: string, params: { name: string }) => this.requestApi<Category>({
    url: `store-session/categories/${categoryId}`,
    method: 'PATCH',
    data: params
  });

  createStoreAttribute = (params: { 
    name: string,
    description?: string,
    categoryId?: string,
   }) => this.requestApi<Attribute>({
    url: `store-session/attributes`,
    data: params
  });

  modifyStoreAttribute = (attributeId: string, params: { 
    name?: string,
    description?: string,
    categoryId?: string,
   }) => this.requestApi<Attribute>({
    url: `store-session/attributes/${attributeId}`,
    method: 'PATCH',
    data: params
  });

  createStoreGood = (params: { 
    name: string,
    description?: string,
    categoryId?: string,
   }) => this.requestApi<Good>({
    url: `store-session/goods`,
    data: params
  });

  modifyStoreGood = (goodId: string, params: { 
    name?: string,
    description?: string,
    categoryId?: string,
   }) => this.requestApi<Good>({
    url: `store-session/goods/${goodId}`,
    method: 'PATCH',
    data: params
  });

  storeResourceTaskInfo = (taskId: string) => this.requestApi<ResourceTask>({
    url: `store-session/resourceTasks/${taskId}`,
  });

  setStoreGoodAttribute = (goodId: string, params: {
    id: string,
    value: AttributeValue
  }[]) => this.requestApi({
    url: `store-session/goods/${goodId}/actions/setAttribute`,
    data: params
  });

  clearStoreGoodAttribute = (goodId: string) => this.requestApi({
    url: `store-session/goods/${goodId}/actions/clearAttribute`,
    method: 'POST'
  });

  clearStoreGoodCategory = (goodId: string) => this.requestApi({
    url: `store-session/goods/${goodId}/actions/clearCategory`,
    method: 'POST'
  });

  createUser = (params: { 
    name: string,
    password: string,
   }) => this.requestApi<User>({
    url: `users`,
    data: { ...params, login: params.name }
  });

  changeUserPassword = (userId: string, password: string) => this.requestApi({
    url: `users/${userId}/actions/changePassword`,
    data: { newPassword: password }
  });

}

function requestParams(params?: { [key: string]: any | undefined }, prefix: string = '?') {
  if (!params) return '';
  const content = Object.keys(params)
      .map(k => {
        const v = params[k];
        const checkV = (ok: any, ov: any) => {
          if (typeof ov == 'object') {
            const { k, v } = ov;
            return typeof k == 'string' && v && typeof v != 'object' ? `${k}=${encodeURIComponent(v)}` : undefined;
          } else if (ov != undefined) return `${ok}=${encodeURIComponent(ov)}`;
        }
        return Array.isArray(v) ? v.map(e => checkV(k, e)).filter(e => e !== undefined).join('&') : checkV(k, v);
      }).filter(v => v).join('&');
  return content.length > 0 ? `${prefix}${content}` : '';
}

function joinUrl(baseUrl: string, ...paths: string[]): string {
  if (baseUrl.length > 0) {
    if (baseUrl.indexOf('://') < 0)
      baseUrl = 'http://' + baseUrl;
    if (baseUrl.endsWith('/'))
      baseUrl = baseUrl.substring(0, baseUrl.length - 1);
  }
  if (paths != null) {
    paths.forEach(v => baseUrl += v.startsWith('/') ? v : '/' + v);
  }
  return baseUrl.startsWith('/') ? baseUrl.substring(1) : baseUrl;
}

function createPageHeader(options?: {page?: RequestPageInfo} | RequestPageInfo) {
  const p = options ? ((options as {page?: RequestPageInfo})?.page ?? options as RequestPageInfo) : undefined;
  if (p) {
    const {offset, size} = p;
    return { offset: (offset ?? 0).toString(), size: (size ?? 20).toString() }
  }
}

function createHeader(headers: { [key: string]: any | undefined }) {
  const t: {[key: string]: string} = {};
  Object.keys(headers).forEach(k => {
    const v = headers[k];
    if (v !== undefined) t[k] = v.toString();
  });
  return t;
}

export default Repository;
