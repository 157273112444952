import { Good } from "@/data/good"
import { Form, Input, Select } from "antd"
import { useAtomValue } from "jotai";
import { repoAtom } from "@/context/atom";
import { CategoryOption } from "@/component/category/opton";
import { Category } from "@/data/category";
import { useTranslation } from "react-i18next";

export default (props: {
    good: Good,
    options?: CategoryOption[],
    onUpdate: (good: Good) => void,
}) => {
    const { good, options, onUpdate } = props;
    const repo = useAtomValue(repoAtom);
    const {t} = useTranslation();
    return <Form id="edit-content"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={{...good, categoryId: good.categories?.length ? good.categories[0].id : undefined}}
        onFinish={async v => {
           const uv = await (good.id ? repo.modifyStoreGood(good.id, v)
            : repo.createStoreGood(v));
            let c: Category|undefined = undefined;
            if (options && v.categoryId) {
                for (let o of options) {
                    if (o.value == v.categoryId) c = o.category; else if (o.options) for (let io of o.options) {
                        if (io.value == v.categoryId) {
                            c = io.category;
                            break;
                        }
                    }
                    if (c) break;
                }
            }
            c ? repo.setStoreGoodCategory(c.id, uv.id) : good.id ? repo.clearStoreGoodCategory(uv.id) : undefined;
            uv.categories = c ? [c] : undefined; 
            onUpdate(uv);
        }}>
        <Form.Item label={t('goodName')} name='name' rules={[{ required: true, message: 'Please input name!' }]}>
            <Input autoFocus placeholder={t('goodName')}/>
        </Form.Item>
        <Form.Item label={t('description')} name='description'>
            <Input placeholder={t("goodDescription")}/>
        </Form.Item>
        {options ? <Form.Item label={t('category')} name='categoryId'>
            <Select options={options} placeholder={t('noCategory')} allowClear></Select>
        </Form.Item> : null}
    </Form>
}
