import {useTranslation} from "react-i18next";
import styles from './App.module.css';
import AttributePage from "./page/attribute";
import GoodPage from "./page/good";
import AttributeCategoryPage from "./page/category/attribute-category";
import CategoryPage from "./page/category";
import UserPage from "./page/user";

// import logo from './logo.svg';
import {
  Layout,
  Menu,
  Avatar,
  Button,
  Spin,
  Space, 
  theme,
  Dropdown,
  Typography,
} from 'antd';

import {
  UsergroupAddOutlined,
  VideoCameraOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  AreaChartOutlined,
  AppstoreOutlined,
  AppstoreAddOutlined,
} from '@ant-design/icons';

import {
  BrowserRouter, Navigate,
  Route,
  Routes, useLocation, useNavigate,
} from 'react-router-dom';

import {repoAtom, userSessionAtom} from "@/context/atom";
import {useAtomValue, useAtom, useSetAtom} from "jotai";
import background from "@/asset/background.png";
import Login from "@/component/login";
import LangSelect from "@/component/lang-select";
import {useEffect, useState} from "react";
import Repository from "@/repository";
import {User} from "@/data/user";
import './App.css';

export default () => {
  const repo = useAtomValue(repoAtom);
  const [userSession, setUserSession] = useAtom(userSessionAtom);
  const [restoring, setRestoring] = useState(true);
  useEffect(() => {
    if (repo.hasSession()) {
      setRestoring(true);
      repo.info()
          .then(u => {
            repo.setSession({ token: u.token });
            setUserSession(u);
          })
          .catch(e => e.response?.status == 401 ? repo.removeSession() : null)
          .finally(() => setRestoring(false));
    } else {
      setRestoring(false);
    }
  }, [repo]);
  const {t} = useTranslation()
  const basename = document
      ?.querySelector('meta[name="base"]')
      ?.getAttribute("content") ?? process.env.REACT_APP_BASE_PATH;
  return <BrowserRouter basename={basename}>
    <Routes>
      <Route path="*" element={restoring || !userSession ? <LoginView repo={repo} loading={restoring}/>
          : <MainView repo={repo} user={userSession.user} onLogout={() => {
            setUserSession(null);
            repo.removeSession();
          }}/>}/>
    </Routes>
  </BrowserRouter>
};

const LoginView = (props: {repo: Repository, loading?: boolean}) => {
  const {repo, loading} = props;
  const {t} = useTranslation();
  const setUserSession = useSetAtom(userSessionAtom);
  return <div className={styles.loadingContainer}>
    <img src={background} className={styles.background} alt=''/>
    {loading ? <Spin size='large'/> : <Space direction="vertical"
                                             align="center"
                                             size={22}
                                             style={{marginTop: 40}}>
      <span className={styles.appTitle}>{t('platformName')}</span>
      <div className={styles.appDescription}><span style={{color: '#4A9AFF'}}>{t('platformSlogan')}</span>，{t('platformSlogan1')}</div>
      <Login onLogin={(s, r) => {
        repo.setSession({ token: s.token });
        setUserSession(s);
        if (r) repo.storeSession();
      }} />
      <LangSelect style={{
        position: 'absolute',
        top: 10,
        right: 10
      }} />
      <div style={{ position: 'absolute', 
        fontSize: 12,
        color: 'white',
        bottom: 10, left: 10, right: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'
      }}>
        <Typography.Link href="https://beian.miit.gov.cn">渝ICP备2021007903号-1</Typography.Link>
      </div>
    </Space>}
  </div>;
}

const MainView = (props: {
  repo: Repository,
  user: User,
  onLogout: () => void,
}) => {
  const {user, repo, onLogout} = props;
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const {name, avatar } = user;
  const headerButtonStyle = { width: 64, height: 64 };
  const {t} = useTranslation();
  return <Layout style={{height: '100vh'}}>
    <Layout.Sider trigger={null} collapsible collapsed={collapsed}>
      <div className={styles.appLogo} />
      <MainMenu permissions={user.permissions ?? []}/>
    </Layout.Sider>
    <Layout style={{position: 'relative', height: '100%', background: 'red'}}>
      <Layout.Header
          className={styles.appHeader}
          style={{background: colorBgContainer}}>
        <Button type="text"
                style={headerButtonStyle}
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}/>
        <div style={{ flex: 1 }}></div>
        <Dropdown trigger={['click']} menu={{items: [{
          label: t('logout'),
          key: 'logout'
        }], onClick: ({key}) => {
          switch(key) {
            case 'logout':
              onLogout();
              break;
          }
        }}}>
          <Button type='text' style={headerButtonStyle}>
            <Avatar src={avatar ? repo.processUrl(avatar) : null}>{name?.substring(0, 1).toUpperCase()}</Avatar>
          </Button>
        </Dropdown>
      </Layout.Header>
      <Layout className={styles.appContent}>
        <Layout.Header className={styles.appHeaderPlacer}/>
        <Layout.Content
            style={{
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              position: 'relative'
            }}>
            <Routes>
              <Route path="/good" element={<GoodPage/>}/>
              <Route path="/attribute" element={<AttributePage/>}/>
              <Route path="/user" element={<UserPage/>}/>
              <Route path="/attribute-category" element={<AttributeCategoryPage/>}/>
              <Route path="/good-category" element={<CategoryPage type={1}/>}/>
              <Route path="/" element={<Space direction='vertical'>
                <Button type="link" href="good">{t('goodEdit')}</Button>
                <Button type="link" href="attribute">{t('attributeEdit')}</Button>
                <Button type="link" href="attribute-category">{t("attributeCategoryEdit")}</Button>
                <Button type="link" href="good-category">{t("goodCategoryEdit")}</Button>
              </Space>}/>
              <Route path="*" element={<Navigate to="/" />}/>
            </Routes>
        </Layout.Content>
      </Layout>
    </Layout>
  </Layout>
}

const MainMenu = (props: {permissions: string[]}) => {
  const {permissions} = props;
  const location = useLocation();
  const pathname = location.pathname;
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const {t} = useTranslation();
  useEffect(() => {
    const selectedKey = pathname.split('/').filter(v => v.length > 0)[0] ?? '';
    setSelectedKeys([selectedKey]);
  }, [pathname]);
  const navigate = useNavigate();
  const contentMenus = [
    { key: 'good', icon: <VideoCameraOutlined />, label: t('goodList'),},
    { key: 'good-category', icon: <AppstoreAddOutlined />, label: t('goodCategory'),},
    { key: 'attribute', icon: <AppstoreOutlined />, label: t('attributeList'),},
    { key: 'attribute-category', icon: <AppstoreAddOutlined />, label: t('attributeCategory'),},
  ];
  if (permissions.indexOf('platform-view-user') >= 0 || permissions.indexOf('platform-manage-user') >= 0) {
    contentMenus.push({ key: 'user', icon: <UsergroupAddOutlined />, label: t('userList'), });
  }
  return <Menu
      theme="dark"
      mode="inline"
      selectedKeys={selectedKeys}
      onSelect={e => {
        const paths = e.keyPath.reverse().join('/');
        navigate(paths);
      }}
      items={[
         {
        key: '',
        icon: <AreaChartOutlined />,
        label: t('home'),
      }, {
        type: 'group',
        label: t('content'),
        children: contentMenus
          /*
          { key: 'video', icon: <VideoCameraOutlined />, label: '剪辑视频',},
          { key: 'library', icon: <AppstoreOutlined />, label: '素材库',},
          { key: 'publication', icon: <AppstoreAddOutlined />, label: '分发配置',},
          { key: 'keyword', label: '关键词配置', icon: <TagsOutlined />},
          { key: 'comment', label: '回复配置', icon: <CommentOutlined />},
           */
      }, /* {
        type: 'group',
        label: '跟进',
        children: [
          { key: 'data', label: '投放数据', icon: <DotChartOutlined />},
          { key: 'commit', icon: <UsergroupAddOutlined />, label: '留资用户'},
        ]
      }, { type: 'divider' } */
      ]}
  />;
}